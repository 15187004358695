<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="white" text rounded class="ma-4">
          Impressum
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Impressum</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pt-2 pb-2">
          <v-container>
            <p>
              <br />
              Rafal Ringwelski<br />
              Martin-Wagner-Ring 17B<br />
              12359 Berlin<br />
              <br />
              E-Mail: m3n70r[at]gmx.de<br />
              Internet: www.rafal-ringwelski.de<br />
              <br />
              Bildrechte: https://www.pexels.com
            </p>
            <br /><br /><br />
            <h1>Disclaimer - Haftungsausschluss</h1>
            <br />
            <h3>Haftung für Inhalte</h3>
            <p>
              Obwohl wir uns um Aktualität, Vollständigkeit und Richtigkeit der
              Inhalte unserer Seiten bemühen, können wir hierfür keine Garantie
              übernehmen.
            </p>
            <p>
              Nach § 7 Absatz 1 TMG sind wir als Diensteanbieter für eigene
              Inhalte auf unseren Seiten nach den allgemeinen Gesetzen
              verantwortlich.
            </p>
            <p>
              Eine Verpflichtung zur Überwachung übermittelter oder
              gespeicherter fremder Informationen besteht jedoch nicht (§§ 8-10
              TMG). Sobald uns Rechtsverstöße bekannt werden, werden wir die
              entsprechenden Inhalte umgehend entfernen. Eine dahingehende
              Haftung wird jedoch erst ab dem Zeitpunkt der Kenntnis konkreter
              Rechtsverletzungen übernommen.
            </p>
            <h3>Haftung für Links</h3>
            <p>
              Unsere Seiten enthalten Links auf externe Webseiten Dritter. Auf
              die Inhalte dieser direkt oder indirekt verlinkten Webseiten haben
              wir keinen Einfluss. Für die Richtigkeit der Inhalte ist immer der
              jeweilige Anbieter oder Betreiber verantwortlich, weshalb wir
              diesbezüglich keinerlei Gewähr übernehmen.
            </p>
            <p>
              Die fremden Webseiten haben wir zum Zeitpunkt der Verlinkung auf
              mögliche Rechtsverstöße überprüft. Zum Zeitpunkt der Verlinkung
              waren keinerlei Rechtsverletzungen erkennbar. Eine ständige
              Überprüfung sämtlicher Inhalte der von uns verlinkten Seiten ohne
              tatsächliche Anhaltspunkte für einen Rechtsverstoß können wir
              nicht leisten. Falls uns Rechtsverletzungen bekannt werden, werden
              wir die entsprechenden Links sofort entfernen.
            </p>
            <h3>Urheberrecht</h3>
            <p>
              Die durch den Betreiber dieser Seite erstellten Inhalte und Werke
              auf diesen Webseiten unterliegen dem deutschen Urheberrecht.
              Sämtliche Beiträge Dritter sind als solche gekennzeichnet. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Kopien von diesen Seiten sind nur für den privaten Bereich
              gestattet, nicht jedoch für kommerzielle Zwecke.
            </p>
            <h3>Datenschutz</h3>
            <p>
              Für die Sicherheit der Datenübertragung im Internet können wir
              keine Gewähr übernehmen, insbesondere besteht bei der Übertragung
              von Daten per E-Mail die Gefahr des Zugriffs durch Dritte.
            </p>
            <p>
              Im Falle der Erhebung personenbezogener oder geschäftlicher Daten
              (E-Mail-Adressen, Namen, Anschriften) auf unseren Seiten erfolgt
              die Preisgabe dieser Daten seitens des Nutzers stets auf
              freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller
              angebotenen Dienste ist auch ohne Angabe solcher Daten bzw. unter
              Angabe anonymisierter Daten oder eines Pseudonyms gestattet,
              soweit dies technisch möglich und zumutbar ist.
            </p>
            <p>
              Einer Nutzung der im Impressum veröffentlichten Kontaktdaten durch
              Dritte zu Werbezwecken wird hiermit ausdrücklich widersprochen.
              Der Betreiber behält sich für den Fall unverlangt zugesandter
              Werbe- oder Informationsmaterialien ausdrücklich rechtliche
              Schritte vor.
            </p>
            <h3>Rechtswirksamkeit dieses Haftungsausschlusses</h3>
            <p>
              Vorstehender Haftungsausschluss ist Teil des Internetangebots, von
              dem aus auf diese Seite verwiesen wurde. Sollten einzelne
              Regelungen oder Formulierungen dieses Haftungsausschlusses
              unwirksam sein oder werden, bleiben die übrigen Regelungen in
              ihrem Inhalt und ihrer Gültigkeit hiervon unberührt.
            </p>
            <p>
              Quelle: <a href="http://www.anwalt-seiten.de">anwalt-seiten.de</a>
            </p>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'ImpressumDialog',
  data() {
    return {
      dialog: false
    }
  }
}
</script>
