var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('section',{attrs:{"id":"hero"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-img',{staticClass:"welcome-img",attrs:{"min-height":'calc(50vh - ' + _vm.$vuetify.application.top + 'px)',"src":require('@/assets/welcome.jpeg')}},[_c('v-theme-provider',{attrs:{"dark":""}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{staticClass:"white--text mx-auto dark-background pa-4",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","tag":"h1"}},[_c('v-row',{staticClass:"white--text ",attrs:{"align":"start","justify":"start"}},[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pb-6"},[_c('span',{staticClass:"font-weight-light",class:[
                            _vm.$vuetify.breakpoint.smAndDown
                              ? 'display-1'
                              : 'display-2'
                          ]},[_vm._v(" WELCOME TO ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-1"},[_c('span',{staticClass:"font-weight-black",class:[
                            _vm.$vuetify.breakpoint.smAndDown
                              ? 'display-1'
                              : 'display-2'
                          ]},[_vm._v(" RAFAL RINGWELSKI ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('span',{staticClass:"font-weight-light",class:[
                            _vm.$vuetify.breakpoint.smAndDown ? 'h5' : 'h5'
                          ]},[_vm._v(" WEB / FRONTEND DEVELOPER ")])])],1)],1)],1)],1),_c('v-btn',{staticClass:"align-self-end",attrs:{"fab":"","outlined":""},on:{"click":function($event){return _vm.$vuetify.goTo('#about-me')}}},[_c('v-icon',[_vm._v("mdi-chevron-double-down")])],1)],1)],1)],1)],1)],1)],1),_c('section',{attrs:{"id":"about-me"}},[_c('div',{staticClass:"py-12"}),_c('v-container',{staticClass:"text-center"},[_c('h2',{staticClass:"display-2 font-weight-bold mb-3"},[_vm._v("ABOUT ME")]),_c('v-responsive',{staticClass:"mx-auto mb-8",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-responsive',{staticClass:"mx-auto title font-weight-light mb-8",attrs:{"max-width":"720"}},[_vm._v(" Hello! My name is Rafal Ringwelski, and i'm a Berlin-based Web-Developer."),_c('br'),_c('br'),_vm._v(" I have a diverse set of skills, ranging from design, to html + css + javascript, php and sql-databases. I make web applications usually with vue.js and enjoy the benefits of a agile process like scrum. DevOps and Jira are a great asset that I also enjoy working with. ")]),_c('v-avatar',{staticClass:"elevation-12 mb-12",attrs:{"size":"128"}},[_c('v-img',{attrs:{"src":require('@/assets/profile-small.jpg')}})],1)],1),_c('div',{staticClass:"py-12"})],1),_c('section',{attrs:{"id":"stats"}},[_c('v-parallax',{attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? 700 : 500,"src":"https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{staticClass:"mx-auto"},_vm._l((_vm.stats),function(ref){
                          var value = ref[0];
                          var title = ref[1];
return _c('v-col',{key:title,attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"display-3 font-weight-black mb-4",domProps:{"textContent":_vm._s(value)}}),_c('div',{staticClass:"title font-weight-regular text-uppercase",domProps:{"textContent":_vm._s(title)}})])])}),1)],1)],1)],1),_c('section',{attrs:{"id":"blog"}},[_c('div',{staticClass:"py-12"}),_c('v-container',[_c('h2',{staticClass:"display-2 font-weight-bold mb-3 text-uppercase text-center"},[_vm._v(" Blog ")]),_c('v-responsive',{staticClass:"mx-auto mb-12",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-row',_vm._l((_vm.articles),function(ref,i){
                          var src = ref.src;
                          var text = ref.text;
                          var title = ref.title;
return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-img',{staticClass:"mb-4",attrs:{"src":src,"height":"275","max-width":"100%"}}),_c('h3',{staticClass:"font-weight-black mb-4 text-uppercase",domProps:{"textContent":_vm._s(title)}}),_c('div',{staticClass:"title font-weight-light mb-5",domProps:{"textContent":_vm._s(text)}})],1)}),1)],1),_c('div',{staticClass:"py-12"})],1),_c('v-footer',{attrs:{"color":"#292929","padless":""}},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{staticClass:"mr-3",attrs:{"cols":"6","sm":"2","md":"1"}},[_c('ImprintDialog')],1),_c('v-col',{staticClass:"ml-3",attrs:{"cols":"6","sm":"2","md":"1"}},[_c('PrivacyDialog')],1),_c('v-col',{staticClass:"py-4 text-center white--text",attrs:{"color":"#ff0000","cols":"12"}},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v("Rafal-Ringwelski.de")]),_c('br'),_vm._v(" Made with love and passion ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }