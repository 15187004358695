<template>
  <div class="home">
    <section id="hero">
      <v-row no-gutters>
        <v-img
          class="welcome-img"
          :min-height="'calc(50vh - ' + $vuetify.application.top + 'px)'"
          :src="require('@/assets/welcome.jpeg')"
        >
          <v-theme-provider dark>
            <v-container fill-height>
              <v-row
                align="center"
                class="white--text mx-auto dark-background pa-4"
                justify="center"
              >
                <v-col class="text-center" cols="12" tag="h1">
                  <v-row align="start" class="white--text " justify="start">
                    <v-col>
                      <v-row>
                        <v-col class="pb-6">
                          <span
                            :class="[
                              $vuetify.breakpoint.smAndDown
                                ? 'display-1'
                                : 'display-2'
                            ]"
                            class="font-weight-light"
                          >
                            WELCOME TO
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pb-1">
                          <span
                            class="font-weight-black"
                            :class="[
                              $vuetify.breakpoint.smAndDown
                                ? 'display-1'
                                : 'display-2'
                            ]"
                          >
                            RAFAL RINGWELSKI
                          </span>
                        </v-col></v-row
                      >
                      <v-row>
                        <v-col class="pt-0"
                          ><span
                            class="font-weight-light"
                            :class="[
                              $vuetify.breakpoint.smAndDown ? 'h5' : 'h5'
                            ]"
                          >
                            WEB / FRONTEND DEVELOPER
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>

                <v-btn
                  class="align-self-end"
                  fab
                  outlined
                  @click="$vuetify.goTo('#about-me')"
                >
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>

    <section id="about-me">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">ABOUT ME</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive
          class="mx-auto title font-weight-light mb-8"
          max-width="720"
        >
          Hello! My name is Rafal Ringwelski, and i'm a Berlin-based
          Web-Developer.<br /><br />
          I have a diverse set of skills, ranging from design, to html + css +
          javascript, php and sql-databases. I make web applications usually
          with vue.js and enjoy the benefits of a agile process like scrum.
          DevOps and Jira are a great asset that I also enjoy working with.
        </v-responsive>

        <v-avatar class="elevation-12 mb-12" size="128">
          <v-img :src="require('@/assets/profile-small.jpg')"></v-img>
        </v-avatar>
      </v-container>

      <div class="py-12"></div>
    </section>

    <!--<section id="features" class="grey lighten-3">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">My Profile</h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ icon, title, text }, i) in myProfile"
            :key="i"
            cols="12"
            md="4"
          >
            <v-card class="py-12 px-4" color="grey lighten-5" flat>
              <v-theme-provider dark>
                <div>
                  <v-avatar color="primary" size="88">
                    <v-icon large v-text="icon"></v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title
                class="justify-center font-weight-black text-uppercase"
                v-text="title"
              ></v-card-title>

              <v-card-text class="subtitle-1" v-text="text"> </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>-->

    <section id="stats">
      <v-parallax
        :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
        src="https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
      >
        <v-container fill-height>
          <v-row class="mx-auto">
            <v-col
              v-for="[value, title] of stats"
              :key="title"
              cols="12"
              md="3"
            >
              <div class="text-center">
                <div
                  class="display-3 font-weight-black mb-4"
                  v-text="value"
                ></div>

                <div
                  class="title font-weight-regular text-uppercase"
                  v-text="title"
                ></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </section>

    <section id="blog">
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
          Blog
        </h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ src, text, title }, i) in articles"
            :key="i"
            cols="12"
            md="4"
          >
            <v-img
              :src="src"
              class="mb-4"
              height="275"
              max-width="100%"
            ></v-img>

            <h3
              class="font-weight-black mb-4 text-uppercase"
              v-text="title"
            ></h3>

            <div class="title font-weight-light mb-5" v-text="text"></div>

            <!--<v-btn class="ml-n4 font-weight-black" text>
              Continue Reading
            </v-btn>-->
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

    <!--<v-sheet id="contact" color="#333333" dark tag="section" tile>
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
          Contact Me
        </h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-theme-provider light>
          <v-row>
            <v-col cols="12">
              <v-text-field flat label="Name*" solo></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field flat label="Email*" solo></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field flat label="Subject*" solo></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea flat label="Message*" solo></v-textarea>
            </v-col>

            <v-col class="mx-auto" cols="auto">
              <v-btn color="accent" x-large>
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-theme-provider>
      </v-container>

      <div class="py-12"></div>
    </v-sheet>-->
    <v-footer color="#292929" padless>
      <v-row justify="center" align="center" no-gutters>
        <v-col cols="6" sm="2" md="1" class="mr-3">
          <ImprintDialog />
        </v-col>
        <v-col cols="6" sm="2" md="1" class="ml-3">
          <PrivacyDialog />
        </v-col>
        <v-col color="#ff0000" class="py-4 text-center white--text" cols="12">
          &copy; {{ new Date().getFullYear() }} —
          <strong>Rafal-Ringwelski.de</strong><br />
          Made with love and passion
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>
<script>
import ImprintDialog from '@/components/ImprintDialog'
import PrivacyDialog from '@/components/PrivacyDialog'
export default {
  name: 'Home',
  components: { ImprintDialog, PrivacyDialog },
  data() {
    return {
      articles: [
        // {
        //   src:
        //     'https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
        //   title: 'Mobile first & Responsive',
        //   text:
        //     'Phasellus lorem enim, luctus ut velit eget, convallis egestas eros. Sed ornare ligula eget tortor tempor, quis porta tellus dictum.'
        // },
        // {
        //   src:
        //     'https://images.unsplash.com/photo-1475938476802-32a7e851dad1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
        //   title: 'Think outside the box',
        //   text:
        //     'Nam ut leo ipsum. Maecenas pretium aliquam feugiat. Aenean vel tempor est, vitae tincidunt risus. Sed sodales vestibulum nibh.'
        // },
        {
          src:
            'https://images.unsplash.com/photo-1416339442236-8ceb164046f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1892&q=80',
          title: 'Small changes, big difference',
          text:
            'Rafal-Ringwelski.de shines in a new design. Work still in progress, so stay tuned.'
        }
      ],
      myProfile: [
        {
          icon: 'mdi-account-group-outline',
          title: 'Teamplayer',
          text: 'Solo is good, but teamplay is better.'
        },
        {
          icon: 'mdi-update',
          title: 'Frequent Updates',
          text: 'Stay up-to-date'
        },
        {
          icon: 'mdi-shield-outline',
          title: 'Long-term Support',
          text:
            'No matter how old a project is, i support you and your applications.'
        }
      ],
      stats: [
        ['15j+', 'Experience'],
        ['100+', 'Applications'],
        ['Countless', 'Lines of code'],
        ['0', 'Unsatisfied customers']
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.welcome-img {
  height: 100vh;
  max-height: 100vh;
}
.dark-background {
  background: rgba(0, 0, 0, 0.5);
}
</style>
